<template>
  <div class="view-page">
    <TitlteH1 title="Настройки профиля" class="mb-12"/>
    <v-row>
      <v-col cols="12" lg="5">
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
        >
          <v-row class="justify-center">
            <v-col cols="12" class="py-0">
              <v-text-field
                  v-model.trim="user.email"
                  :rules="rules.emailRules"
                  key="email"
                  label="Почта"
                  required
              />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" class="py-0">
              <v-text-field
                  v-model.trim="user.firstName"
                  :rules="rules.globalRules"
                  key="name"
                  label="Имя"
                  required
              />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" class="py-0">
              <v-text-field
                  v-model.trim="user.lastName"
                  :rules="rules.globalRules"
                  key="name"
                  label="Фамилия"
                  required
              />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" class="py-0">
              <v-text-field
                  v-model="userPhone"
                  key="phone"
                  label="Телефон"
                  :rules="rules.globalRules"
                  disabled
              />
            </v-col>
          </v-row>
          <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              label="Новый пароль"
              :rules="password ? [rules.password.registerMin] : []"
              @click:append="showPassword = !showPassword"
          />
          <v-row class="mt-3 justify-center">
            <v-col cols="12">
              <v-btn
                  :small="mobile"
                  type="submit"
                  color="primary"
                  block
              >
                Сохранить
              </v-btn>
            </v-col>
          </v-row>

        </v-form>
      </v-col>
    </v-row>


  </div>
</template>

<script>
import TitlteH1 from "@/components/typography/TitlteH1";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import store from "@/store";

export default {
  name: "Settings",
  components: {TitlteH1},
  data() {
    return {
      password: '',
      showPassword: false,
      valid: true,
      rules: {
        emailRules: [
          v => !!v || 'E-mail обязателен',
          v => /.+@.+\..+/.test(v) || 'E-mail заполнен неверно',
        ],
        globalRules: [
          v => !!v || 'Поле обязательно',
        ],
        password: {
          registerMin: v => v.length >= 5 || 'Пароль должен быть длиннее 4 символов'
        },
      }
    }
  },
  methods: {
    async submit() {
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) {
        return
      }
      this.setIsLoading(true)
      try {
        const response = await axios({
          baseURL: this.URL + '/api/user/user',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': 'Bearer ' + this.token
          },
          method: 'PATCH',
          data: JSON.stringify({
            "plainPassword": this.password ? this.password : '',
            "email": this.user.email,
            "lastName": this.user.lastName,
            "firstName": this.user.firstName
          })
        })
        this.setIsAlert(true);
        this.changeAlertMessage('Настройки изменены')
        this.changeAlertType('success')
        setTimeout(() => {
          this.setIsAlert(false);
        }, 1500)
        await this.$store.dispatch('loginUser', {token: this.token})
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    ...mapActions(['setIsLoading', 'setIsAlert', 'changeAlertType', 'changeAlertMessage'])
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs
    },

    userPhone() {
      return this.user.phone.replace(/(.)(...)(...)(..)(..)/, '+$1 ($2) $3-$4-$5')
    },
    ...mapGetters(['user', 'URL', 'token'])
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="view-page">
    <TitlteH1 title="Найденные фотографии"/>
    <div class="subheader" v-if="cartArray?.length">
      <v-btn @click="$router.push({name: 'cart'})" color="primary" :small="mobile" width="100%">Открыть корзину (Товаров: {{cartArray.length}})</v-btn>
    </div>
    <div class="subheader" v-else>
      <Subtitle subtitle="Нажмите на сердечко, чтобы сохранить фотографию в «Избранное»"/>
    </div>

    <div class="empty" style="text-align: center; margin-top: 42px" v-if="emptyLocation">
      - В данной локации пока нет загруженных фотографий -
    </div>
    <div class="content" v-else>
      <v-row>
        <v-col cols="12" lg="4">
          <v-dialog
            ref="dialog"
            v-model="datepicker"
            :return-value.sync="date"
            persistent
            width="320px"
            @input="getAlbum"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                label="Укажите дату"
                hide-details
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                @click:clear="clearQuery"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              scrollable
              first-day-of-week="1"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date)"
              >
                Ок
              </v-btn>
            </v-date-picker>
          </v-dialog>

        </v-col>
      </v-row>
      <GalleryBtns controls search @fav="clickBookmark" @cart="clickCart" class="mt-8" :gallery="returnGallery"/>
      <div
        v-intersect="next"
        class="observer"
      >

      </div>
    </div>
  </div>
</template>

<script>
import TitlteH1 from "@/components/typography/TitlteH1";
import Subtitle from "@/components/typography/Subtitle";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import VGallery from "@/components/gallery/VGallery";
import GalleryBtns from "@/components/gallery/GalleryBtns.vue";
import {add, format} from "date-fns";

export default {
  name: "Find",
  components: {GalleryBtns, VGallery, Subtitle, TitlteH1},
  data() {
    return {
      page: 0,
      gallery: [],
      total: 1,
      query: null,
      returnGallery: [],
      date: '',
      datepicker: false,
      location: null,
      searchedLocations: [],
      emptyLocation: true,
      canLoad: true,
    }
  },
  methods: {
    async next(e, i) {
      if (e[0].isIntersecting && this.total > this.gallery.length && this.canLoad) {
        this.canLoad = false
        this.page++;
        await this.getAllPhotos()
      }
    },
    async getAlbums() {
      this.setIsLoading(true);
      try {
        const response = await axios({
          baseURL: `${this.URL}/api/user/album_locations?location.id=${this.$route.params.location}`,
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })
        if (response.data.length === 0) {
          this.emptyLocation = true;
        } else {
          this.emptyLocation = false;

          this.location = response.data[0].location;
          let arr = response.data.filter(item => item.albums.length > 0).map(item => item.albums);
          let ids = [];
          let arr2 = arr.forEach(item => {
            item.forEach(item => {
              ids.push(item.id)
            })
          })
          let idsString = ids.map(i => '&album.id%5B%5D=' + i).join('')

          this.query = idsString
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    async searchLocations(url) {
      // получаем альбомы при вводе названия локации в автокомплит
      try {
        const response = await axios({
          baseURL: url,
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })

        if (response.data.length > 0) {
          this.searchedLocations = response.data[0].albums.map(item => '&album.id%5B%5D=' + item.id).join('')
        } else {
          this.gallery = []
        }
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    async getAllPhotos() {
      //получаем все фото что есть при первой загрузке страницы
      this.setIsLoading(true)
      this.changeAlertType('info');
      this.changeAlertMessage(
        `<span style="text-align: center; display: block" >
Загрузка фотографий, чуть-чуть подождите           </span>`
      );
      try {
        const response = await axios({
          baseURL: this.date ? this.albumUrl : this.searchUrl,
          headers: {
            'Accept': 'application/ld+json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })
        if (response.data['hydra:member'].length === 0) {
          this.gallery = [];
        } else {
          this.gallery = [...this.gallery, ...response.data['hydra:member']];
          this.canLoad = true;

        }
        this.total = response.data['hydra:totalItems'];
        this.mapGallery()
        setTimeout(() => {
          this.setIsAlert(false)
        }, 3000)
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    clickBookmark(img) {
      //клик по сердечку. сверяем id кликнутой фотки с вхождением в массив закладок
      //если есть, то удаляем, если нет то добавляем
      let idx = this.bookmarksArray.indexOf(img.id)
      if (idx !== -1) {
        const photo = this.bookmarksArrayWithId.find(i => i.photo === img.id)
        this.deleteBookmark({string: photo.id})
        this.bookmarksArray.splice(idx, 1)
        this.setIsAlert(true);
        this.changeAlertMessage('<span style="text-align: center; display: block" >Фотография удалена из избранного</span>');
        this.changeAlertType('success')
        setTimeout(() => {
          this.setIsAlert(false);
        }, 1500)
      } else {
        this.setIsAlert(true);
        this.changeAlertMessage('<span style="text-align: center; display: block" >Фотография добалена в избранное</span>');
        this.changeAlertType('success')
        setTimeout(() => {
          this.setIsAlert(false);
        }, 1500)
        this.bookmarksArray.push(img.id);
        this.addBookmark({string: img['@id']})

      }
      this.mapGallery()
    },
    clickCart(img) {
      //клик по корзине. сверяем id кликнутой фотки с вхождением в массив закладок
      //если есть, то удаляем, если нет то добавляем
      let idx = this.cartArray.indexOf(img.id)
      if (idx !== -1) {
        this.setIsAlert(true);
        this.changeAlertMessage('<span style="text-align: center; display: block" >Фото уже в корзине</span>');
        this.changeAlertType('success')
        setTimeout(() => {
          this.setIsAlert(false);
        }, 1500)
        return
        // const photo = this.cartArrayWithId.find(i => i.photo === img.id)
        // this.deleteFromCart({string: photo.id})
        // this.cartArray.splice(idx, 1)
      } else {
        if (this.user.cartCount >= 100) {
          this.changeAlertType('error')
          this.setIsAlert(true)
          this.changeAlertMessage('За один раз возможно купить не более 100 фотографий')
          setTimeout(() => {
            this.setIsAlert(false)
          }, 3000)
          return
        }
        this.setIsAlert(true);
        this.changeAlertMessage('<span style="text-align: center; display: block" >Фотография добавлена в корзину</span>');
        this.changeAlertType('success')
        setTimeout(() => {
          this.setIsAlert(false);
        }, 1500)
        this.cartArray.push(img.id);
        this.addToCart({string: img['@id']})

      }
      this.mapGallery()
    },
    mapGallery() {
      //Вводим в галерею новые поля. Избранное и корщина.
      //Основываемся на массиве айдишников получаемых в юзер инфо
      this.returnGallery = this.gallery.map(i => {
        return {...i, bookmarks: this.bookmarksArray.indexOf(i.id) !== -1, cart: this.cartArray.indexOf(i.id) !== -1};
      })

    },
    async clearQuery() {
      this.page = 1;
      this.gallery = [];
      this.total = 0;
      this.date = '';
      this.datepicker = false;
      await this.getAlbums();
      await this.getAllPhotos();
    },
    async getAlbum(e) {
      if (e === false) {
        this.page = 1;
        this.gallery = [];
        this.total = 0;
        this.searchedLocations = [];
        if (this.date !== '') {
          let url = `${this.URL}/api/user/album_locations?page=${this.page}&location.title=${this.location.title}`
          //получаем дату до и после выбранной для запроса
          const before = format(add(new Date(this.date), {days: -1}), 'yyyy-MM-dd');
          const after = format(add(new Date(this.date), {days: 1}), 'yyyy-MM-dd');
          url += `&dateAt%5Bstrictly_before%5D=${after}&dateAt%5Bstrictly_after%5D=${before}`;
          await this.searchLocations(url)
          if (this.searchedLocations.length > 0) {

            await this.getAllPhotos()
          } else {
            this.gallery = []
          }
        }
        this.mapGallery()
      }
    },
    ...mapActions(['setIsLoading', 'setIsAlert', 'changeAlertMessage', 'changeAlertType', 'addBookmark', 'deleteBookmark', 'addToCart', 'deleteFromCart'])
  },
  computed: {
    albumUrl() {
      return `${this.URL}/api/user/albums_photos?order%5BcreatedAt%5D=desc${this.searchedLocations}&page=${this.page}&itemsPerPage=24`;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs
    },
    dateFormatted() {
      if (this.date) {
        const [year, month, day] = this.date.split('-')
        return `${day}.${month}.${year}`
      } else return ''
    },
    disabledLoad() {
      return this.total === this.gallery.length
    },
    searchUrl() {
      return this.URL + '/api/user/albums_photos?order%5BcreatedAt%5D=desc' + this.query + `&page=${this.page}` + '&itemsPerPage=24';
    },
    bookmarksArray() {
      return this.user.bookmarks.map(i => +i.photo.replace(/\D/g, ''))
    },
    bookmarksArrayWithId() {
      return this.user.bookmarks.map(i => {
        return {
          id: i.id,
          photo: +i.photo.replace(/\D/g, '')
        }
      })
    },
    cartArray() {
      return this.user?.cart?.map(i => +i.photo.replace(/\D/g, ''))
    },
    cartArrayWithId() {
      return this.user.cart.map(i => {
        return {
          id: i.id,
          photo: +i.photo.replace(/\D/g, '')
        }
      })
    },
    ...mapGetters(['token', 'user', 'URL'])
  },
  watch: {
  },

  async mounted() {
    await this.getAlbums()
  }
}
</script>

<style scoped>
.subheader {
  margin: 18px 0;
}
</style>

import router from "@/router";
import axios from "axios";
import store from "@/store";


export default {
  state: {
    isAuth: localStorage.getItem('isAuth') ? localStorage.getItem('isAuth') : null,
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    user: null,
  },

  getters: {
    isAuth: (state) => state.isAuth,
    token: (state) => state.token,
    user: (state) => state.user
  },

  mutations: {
    //Получим все данные о пользователе при логине
    LOGIN_USER(state, payload) {
      state.isAuth = 'true';
      state.token = payload.token;
      state.user = payload.user;
      localStorage.setItem('isAuth', 'true');
      localStorage.setItem('token', payload.token);
      localStorage.setItem('user', JSON.stringify(payload.user));
    },
    // Удалим все данные о пользователе при логауте
    LOGOUT_USER(state) {
      state.isAuth = null;
      state.token = null;
      state.user = null;
      localStorage.clear()
    }
  },

  actions: {
    //Получим все данные о пользователе запросом на апи и записываем в payload при логине
    async loginUser({commit}, payload) {
      try {
        const response = await axios({
          baseURL: store.getters.URL + '/api/user/user_info',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${payload.token}`
          },
          method: 'GET',
        })
        if (!response.data) {
          commit('LOGOUT_USER')
        } else {
          payload.user = response.data
          commit('LOGIN_USER', payload)
        }


      } catch (e) {
        console.log(e)
        if (e.response.data.message === 'Expired JWT Token'  || e.response.data.message === 'Invalid credentials.') {
          commit('LOGOUT_USER')
          await router.push({name: 'home'});
          await store.dispatch('setIsAlert', true)
          await store.dispatch('changeAlertMessage', 'Зайдите для продолжения работы с сервисом')
          await store.dispatch('changeAlertType', 'info')
          setTimeout(() => {
            store.dispatch('setIsAlert', false)
          }, 3000)
        }
      } finally {
      }

    },
    // Удалим все данные о пользователе при логауте
    async logoutUser({commit}) {
      commit('LOGOUT_USER')
      await router.push({name: 'home'})
    }

  },
};

<template>
  <div class="view-page">
    <TitlteH1 title="Поиск по месту"/>

    <div class="card">
      <div class="card__title">
        Введите место фотосъемки
      </div>

      <v-row class="justify-center">
        <v-col cols="12" lg="6">
          <v-autocomplete
            :items="searchedLocations"
            item-text="title"
            item-value="id"
            label="Введите название локации"
            v-model="location"
            hide-selected
            hide-no-data
            :append-icon="null"
            clearable
            @click:clear="clearQuery"
            autocomplete="off"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <div class="btn">
        <v-btn
          color="primary"
          :small="mobile"
          block
          :disabled="!location"
          @click="search"
        >
          Искать
        </v-btn>
      </div>
    </div>


  </div>
</template>

<script>
import TitlteH1 from "@/components/typography/TitlteH1";
import Subtitle from "@/components/typography/Subtitle";
import {mapActions, mapGetters} from "vuex";

import axios from "axios";
import {add, format} from "date-fns";


export default {
  name: "SearchByDate",
  components: {Subtitle, TitlteH1},
  data() {
    return {
      page: 1,
      date: '',
      location: null,
      searchString: null,
      datepicker: false,
      searchedLocations: [],
      isLoadLocations: false,
    }
  },

  methods: {
    async searchLocations() {
      // получаем локации в автокомплит
      this.isLoadLocations = true;
      try {
        const response = await axios({
          baseURL: `${this.URL}/api/user/locations`,
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })
        this.searchedLocations = response.data;
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoadLocations = false;
      }
    },
    clearQuery() {
      // this.page = 1;
      // this.searchedLocations = [];
      // this.date = '';
      this.location = '';
      // this.datepicker = false;
      // this.searchString = '';
    },
    async search() {
      await this.$router.push({name: 'find', params: {location: this.location}})
    },
    ...mapActions(['setIsLoading', 'setIsAlert', 'changeAlertMessage', 'changeAlertType'])
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs
    },

    dateFormatted() {
      if (this.date) {
        const [year, month, day] = this.date.split('-')
        return `${day}.${month}.${year}`
      } else return ''
    },

    searchLocationsUrl() {
      //УРЛ для запроса фоток по фильтру
      let url = `${(this.URL)}/api/user/album_locations?page=1`
      if (this.date) {
        //получаем дату до и после выбранной для запроса
        const before = format(add(new Date(this.date), {days: -1}), 'yyyy-MM-dd');
        const after = format(add(new Date(this.date), {days: 1}), 'yyyy-MM-dd');

        url += `&dateAt%5Bstrictly_before%5D=${after}&dateAt%5Bstrictly_after%5D=${before}`
      }
      if (this.searchString) {
        url += `&location.title=${this.searchString}`
      }
      return url
    },
    searchPhotoUrl() {
      //УРЛ для запроса фоток по фильтру
      let url = '';

      if (this.albumsArray) {
        url += this.albumsArray
      }
      return url
    },
    albumsArray() {
      return this.location.map(i => '&album.id%5B%5D=' + i.id).join('');
    },
    ...mapGetters(['token', 'URL'])
  },

  watch: {
    searchString(val) {
      val && val !== this.location && this.searchLocations()
    },
    date() {
      this.searchedLocations = [];
      this.searchString = '';
      this.location = '';
    }
  },
  mounted() {
    this.searchLocations()
  }

}
</script>

<style lang="scss" scoped>
.card {
  max-width: 800px;
  margin: 120px auto 0;
  padding: 90px;
  background: #F7F7F7;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  &__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    max-width: 600px;
    margin: 0 auto 24px;
  }
}

@media (max-width: 1263px) {
  .card {
    margin: 80px auto 0;
    padding: 64px 120px;

    &__title {
      font-size: 24px;
    }
  }
}

@media (max-width: 959px) {
  .card {
    margin: 40px auto 0;
    padding: 64px;

    &__title {
      font-size: 20px;
    }
  }
}

@media (max-width: 599px) {
  .card {
    padding: 32px 20px 64px;

    &__title {
      font-size: 16px;
    }
  }
}

.btn {
  width: 320px;
  margin: 32px auto 0;
  @media (max-width: 475px) {
    width: 100%;
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from "@/views/MainPage";
import NotFound from "@/views/NotFound";
import Profile from "@/views/profile/Profile";
import store from "@/store";
import Photo from "@/views/photo/Photo";
import SearchByDate from "@/views/search/SearchByDate";
import Find from "@/views/find/Find";
import Bookmarks from "@/views/bookmarks/Bookmarks";
import Editor from "@/views/editor/Editor";
import Cart from "@/views/cart/Cart";
import Settings from "@/views/settings/Settings";
import Contacts from "@/views/contacts/Contacts.vue";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainPage,
    meta: {
      title: 'Phototime – фотоуслуги для развлекательного бизнеса',
      metaTags: [
        {
          name: 'description',
          content: 'Phototime самая крупная компания в России по предоставлению фотоуслуг в развлекательной сфере: Работаем в 60 городах'
        },
        {
          property: 'og:description',
          content: 'Phototime самая крупная компания в России по предоставлению фотоуслуг в развлекательной сфере: Работаем в 60 городах'
        }
      ]
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      auth: true,
    }
  },
  {
    path: '/photo',
    name: 'photo',
    component: Photo,
  },
  {
    path: '/search_date',
    name: 'dateSearch',
    component: SearchByDate,
    meta: {
      auth: true,
    }
  },
  {
    path: '/p/:location',
    name: 'find',
    component: Find,
  },
  {
    path: '/bookmarks',
    name: 'bookmarks',
    component: Bookmarks,
    meta: {
      auth: true,
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart,
    meta: {
      auth: true,
    }
  },
  {
    path: '/edit/id:id',
    name: 'editor',
    component: Editor,
    meta: {
      auth: true,
      props: true,
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      auth: true,
    }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
    meta: {
      auth: true,
    }
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }

})
router.beforeEach((to, from, next) => {
  if (store.getters.user === undefined) {
    store.dispatch('logoutUser').then(r => r)
  }

  // if (store.getters.token) {
  //   //если пользователь залогинен, то перед каждым открытием страницы запрашиваем его данные.
  //   //тут и токен проверяется и данные обновляются
  //   store.dispatch('loginUser', {token: store.getters.token}).then(r => {
  //
  //   }).catch(e => store.dispatch('logoutUser'))
  // }

  if (to.matched.some(record => record.meta.auth)) {

    if (!store.getters.isAuth) {
      router.push({name: 'notFound'})
    } else {
      next()
    }
  } else {
    next()
  }
  // обработка оплаты/редиректы из юкассы
  if (to.name === 'profile') {
    if (to.query.key) {
      const key = to.query.key;
      store.commit('CHANGE_IDEMPOTENCE_KEY', key)
    } else {
      store.commit('CHANGE_IDEMPOTENCE_KEY', null)
    }
  }
  if (to.name === 'photo' && !store.getters.isAuth) {
    router.push({name: 'home', query: {needAuth: 'yes'}})
  }
  if (to.name === 'find' && !store.getters.isAuth) {
    router.push({name: 'home', query: {redirect: 'yes', to: to.params.location}})
  }
})
export default router

<template>
  <div class="view-page">
    <TitlteH1 title="Мои фото"/>
    <Subtitle subtitle="Здесь находятся купленные фотографии"/>
    <div class="filtres mb-4 mb-lg-8 mb-xl-10">
      <v-row class="align-end">
        <v-col :cols="date ? 5:6" lg="3">
          <v-select
              :items="photoLocations"
              item-text="location.title"
              item-value="location"
              label="Укажите локацию"
              v-model="location"
              hide-details
              clearable
              @click:clear="clearQuery"
          >
          </v-select>
        </v-col>
        <v-col :cols="date ? 4:6" lg="3" v-if="location">
          <v-dialog
              ref="dialog"
              v-model="datepicker"
              :return-value.sync="date"
              persistent
              width="320px"
              @input="getAlbum"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateFormatted"
                  label="Укажите дату"
                  hide-details
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                scrollable
                first-day-of-week="1"
                :allowed-dates="allowedDates"
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(date)"
              >
                Ок
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="3" lg="3" class="ml-auto" v-if="date">
          <div @click="downloadZip" class="donwload-btn">
            <v-icon color="primary">
              mdi-cloud-download-outline
            </v-icon>
            {{ mobile ? '' : 'Скачать архив' }}
          </div>
        </v-col>
      </v-row>
    </div>
    <GalleryBtns @download="downloadPhoto" @edit="openEditor" :gallery="returnGallery" editor/>
    <div
        v-intersect="next"
        class="observer"
    >
    </div>
    <v-row v-if="this.total === 0" class="mt-4 justify-center">
      <v-col cols="12" lg="3">
        <v-btn
            :small="mobile"
            color="primary"
            block
            @click="$router.push({name: 'dateSearch'})"
        >
          Найти фото
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TitlteH1 from "@/components/typography/TitlteH1";
import Subtitle from "@/components/typography/Subtitle";
import GalleryStepper from "@/components/gallery/VueGallery.vue";
import {mapActions, mapGetters} from "vuex";
import {add, format} from "date-fns";
import axios from "axios";
import VGallery from "@/components/gallery/VGallery";
import {saveAs} from "@/assets/js/libs/FileSaver";
import GalleryBtns from "@/components/gallery/GalleryBtns.vue";
import store from "@/store";

export default {
  name: "Photo",
  components: {GalleryBtns, VGallery, GalleryStepper, Subtitle, TitlteH1},
  data() {
    return {
      page: 0,
      gallery: [],
      total: 1,
      date: '',
      location: '',
      datepicker: false,
      tab: 1,
      status: '',
      album: '',
      canLoad: true,
    }
  },
  methods: {
    async downloadZip() {
      await this.setIsLoading(true)
      // this.setIsAlert(true)
      // this.changeAlertType('info');
      // this.changeAlertMessage(`<span style="text-align: center; display: block" >Формируем архив, пожалуйста, подождите. <br> Загрузка начнется автоматически.          </span>`);
      try {
        const response = await axios({
          baseURL: this.URL + '/api/user/user_archives_with_photos',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.token
          },
          method: 'POST',
          data: JSON.stringify({
            locationId: this.location.id,
            dateAt: this.date
          })
        })

        const url = this.URL + response.data.zip;
        console.log(url)
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    async next(e, i) {
      if (e[0].isIntersecting && this.total > this.gallery.length && this.canLoad) {
        this.canLoad = false
        this.page++;
        await this.getAllPhotos()
      }

    },
    async getAllPhotos() {
      //получаем все фото что есть при первой загрузке страницы
      this.setIsLoading(true)
      try {
        const response = await axios({
          baseURL: this.baseUrl,
          headers: {
            'Accept': 'application/ld+json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })
        if (response.data['hydra:member'].length === 0) {
          this.gallery = [];
        } else {
          this.gallery = [...this.gallery, ...response.data['hydra:member']];
          this.canLoad = true;
        }
        this.total = response.data['hydra:totalItems'];

      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    async getStatusPhoto() {
      // Получаем новый массив фоток
      //Используется при фильтре по типу фото
      //А так же при фильтре по альбому в выпадашках и дате
      this.setIsLoading(true)
      this.changeAlertType('info');
      this.changeAlertMessage(
          `<span style="text-align: center; display: block" >
Загрузка фотографий, чуть-чуть подождите           </span>`
      );
      try {
        const response = await axios({
          baseURL: this.baseUrl,
          headers: {
            'Accept': 'application/ld+json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })
        this.total = response.data['hydra:totalItems'];
        this.gallery = [...response.data['hydra:member']];
        setTimeout(() => {
          this.setIsAlert(false)
        }, 3000)
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    allowedDates(val) {
      return this.formattingDates.includes(val);
    },
    async getAlbum(e) {
      //Запрос альбома после закрытия попапа с датой.
      // лучше события не нашел, остальные не отрабатывают как надо
      // сравниваю полученную в календаре дату со всеми локациями и вытаскиваю второй параметр-id альбома
      //записываю его в переменную, в baseUrl добавляетсястрока и совершаю запрос за фотками
      if (e === false) {
        this.page = 1;
        await this.getStatusPhoto()
      }
    },
    async clearQuery() {
      this.page = 1;
      this.gallery = [];
      this.total = 0;
      this.date = '';
      this.location = '';
      this.datepicker = false;
      this.tab = 1;
      this.status = '';
      this.album = '';
      await this.getAllPhotos()
    },
    openEditor(id) {
      this.$router.push({name: 'editor', params: {id: id}})
    },

    base64ToBlob(data) {

      let mimeString = '';
      let raw, uInt8Array, i, rawLength;

      raw = data.replace(/data:(image\/.+);base64,/, function (header, imageType) {
        mimeString = imageType;

        return '';
      });

      raw = atob(raw);
      rawLength = raw.length;
      uInt8Array = new Uint8Array(rawLength); // eslint-disable-line

      for (i = 0; i < rawLength; i += 1) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], {type: mimeString});
    },
    async downloadPhoto(photo) {
      this.setIsLoading(true)
      try {
        const response = await axios({
          baseURL: `${this.URL}/api/user/user_photos/editor/${photo.id}`,
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })

        let dataUrl = response.data;
        let blob = this.base64ToBlob(dataUrl)
        saveAs(blob, 'image'); // eslint-disable-line

      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },

    ...mapActions(['setIsLoading', 'setIsAlert', 'changeAlertMessage', 'changeAlertType', 'fetchLocations'])
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs
    },
    returnGallery() {
      return this.gallery
    },
    disabledLoad() {
      return this.total === this.gallery.length
    },
    dateFormatted() {
      if (this.date) {
        const [year, month, day] = this.date.split('-')
        return `${day}.${month}.${year}`
      } else return ''
    },
    formattingDates() {
      const dates = [];
      if (this.location.dates) {
        this.location.dates.forEach(date => {
          dates.push(date.slice(0, 10));
        })
      }
      return dates;
    },
    baseUrl() {
      let url = `${(this.URL)}/api/user/user_photos?page=${(this.page)}&itemsPerPage=24&order%5BcreatedAt%5D=desc`
      if (this.date) {
        //получаем дату до и после выбранной для запроса
        const before = format(add(new Date(this.date), {days: -1}), 'yyyy-MM-dd');
        const after = format(add(new Date(this.date), {days: 1}), 'yyyy-MM-dd');
        url += `&dateAt%5Bstrictly_before%5D=${after}&dateAt%5Bstrictly_after%5D=${before}`
      }
      if (this.location) {
        url += `&locationId=${this.location.id}`
      }
      return url
    },
    ...mapGetters(['token', 'URL', 'photoLocations'])
  },
  async mounted() {
    await this.fetchLocations()
  }
}
</script>

<style lang="scss" scoped>
.donwload-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #4442d2;
  cursor: pointer;
  justify-content: center;
}
</style>

<template>
  <v-card>
    <v-card-actions class="pb-0">
      <v-spacer/>
      <v-btn
        icon
        @click="$emit('close')"
        x-small
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-title class="text-center justify-center pt-0">Сброс пароля <br> Введите ваш номер телефона</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="resetPassword"
      >
        <v-row class="justify-center">
          <v-col cols="10" class="py-0">
            <v-text-field
              v-model.trim="reset.phone"
              key="phone"
              label="Телефон"
              v-maska="'+7(###)###-##-##'"
              :rules="rules.phoneRules"
              required
            />
          </v-col>
        </v-row>
        <!--        <v-row class="justify-center">-->
        <!--          <v-col cols="10" class="py-0">-->
        <!--            <v-text-field-->
        <!--              v-model.trim="reset.email"-->
        <!--              :rules="rules.emailRules"-->
        <!--              key="email"-->
        <!--              label="Почта"-->
        <!--              required-->
        <!--            />-->
        <!--          </v-col>-->
        <!--        </v-row>-->
        <v-row class="justify-center">
          <v-col cols="10">
            <v-btn
              :small="mobile"
              type="submit"
              color="primary"
              block
            >
              Отправить новый пароль
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "ResetDialog",
  data() {
    return {
      valid: true,
      showPassword: false,
      policy: true,
      reset: {
        phone: '',
        password: '',
      },

      rules: {
        password: {
          required: value => !!value || 'Пароль обязателен',
          registerMin: v => v.length >= 2 || 'Пароль должен быть длиннее 3 символов'
        },
        phoneRules: [
          v => !!v || 'Телефон обязателен',
        ],
        globalRules: [
          v => !!v || 'Поле обязательно',
        ],
        policyRules: [
          v => !!v || 'Поле обязательно',
        ],
        emailRules: [
          v => !!v || 'E-mail обязателен',
          v => /.+@.+\..+/.test(v) || 'E-mail заполнен неверно',
        ],
      }
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs
    },
    ...mapGetters(['URL'])
  },
  methods: {
    async resetPassword() {
      //валидируем форму
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) {
        return
      }
      this.setIsLoading(true)
      try {
        const response = await axios({
          baseURL: this.URL + '/api/user/pwd_reset',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          data: JSON.stringify({
            phone: this.reset.phone.replace(/\D/g, ''),
            email: this.reset.email,
          })
        })

        if (response.data.status === 'ok') {
          this.$emit('resetSuccess')
          this.setIsAlert(true)
          this.changeAlertType('success')
          this.changeAlertMessage('Новый пароль отправлен по SMS')
          setTimeout(() => {
            this.setIsAlert(false)
          }, 5000)

        }
      } catch (e) {
        this.setIsAlert(true)
        this.changeAlertType('error')
        this.changeAlertMessage('Пользователь с таким номером не зарегистрирован')
        setTimeout(() => {
          this.setIsAlert(false)
        }, 5000)
      } finally {
        this.setIsLoading(false)
      }
    },

    ...mapActions(['setIsLoading', 'setIsAlert', 'changeAlertMessage', 'changeAlertType', 'loginUser'])
  }
}
</script>

<style scoped>

</style>

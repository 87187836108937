<template>
  <div class="view-page">
    <TitlteH1 title="Найти фотографии" class="mb-4"/>
    <Subtitle subtitle="Выберите подходящую опцию и нажмите «Искать»"/>
    <v-slide-group
      v-model="type"
      class="slider"
      center-active
      mandatory
      :show-arrows="false"
    >
      <v-slide-item
        v-for="type in types"
        :key="type.id"
        v-slot="{ active, toggle }"
        active-class="active"
        class="card"
        :value="type"
      >
        <v-card
          class="card-wrapper"
          @click="toggle"
        >
          <img class="img" :src="type.img" alt="">
        </v-card>
      </v-slide-item>
    </v-slide-group>
    <div class="btn">
      <v-btn
        color="primary"
        :small="mobile"
        block
        @click="search"
      >
        Искать
      </v-btn>
    </div>
    <v-dialog width="80%" max-width="800" v-model="showPaid" transition="dialog-bottom-transition">
      <div v-if="successPaid" class="success">
        <v-card>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              icon
              @click="showPaid = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <div class="popup__body">
            <h3>Спасибо! <br> Оплата прошла успешно</h3>
            <div class="text">Купленные фотографии доступны в разделе «Мои фото»</div>
            <v-btn color="primary" block class="mt-4" @click="$router.push({name: 'photo'})">К моим фото</v-btn>
          </div>
        </v-card>
      </div>
      <div v-else class="false">
        <v-card>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              icon
              @click="showPaid = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <div class="popup__body">
            <h3>Возможно ваш платеж еще обрабатывается банком.</h3>
            <div class="text">Если платеж долго обрабатывается <br> Вы можете обратиться в службу поддержки
              support@phototime.pro <br> Код ошибки: {{ idempotenceKey }}
            </div>  </div>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>


import TitlteH1 from "@/components/typography/TitlteH1";
import Subtitle from "@/components/typography/Subtitle";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "Profile",
  components: {Subtitle, TitlteH1},
  data() {
    return {
      showPaid: null,
      successPaid: null,

      type: null,
      types: [
        {
          id: 1,
          img: require('@/assets/img/date.png'),
          route: 'dateSearch'
        },
        // {
        //   id: 2,
        //   img: require('@/assets/img/qr.png'),
        //   route: 'codeSearch',
        // }
      ]
    }
  },
  methods: {
    search() {
      this.$router.push({name: this.type.route})
    },
    async redirectFromYookassa() {
      this.setIsLoading(true)
      try {
        if (this.idempotenceKey) {
          const response = await axios(`${this.URL}/api/user/payments/${this.idempotenceKey}`, {
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${this.token}`
            }
          });
          const isPaid = response.data.paid;
          this.showPaid = true;
          if (isPaid) {
            this.successPaid = true;
          } else {
            this.successPaid = false;
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }

    },
    ...mapActions(['setIsLoading'])
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs
    },
    ...mapGetters(['idempotenceKey', 'URL', 'token'])
  },
  mounted() {
    this.redirectFromYookassa()
  }
}
</script>

<style lang="scss" scoped>
.card {
  opacity: .4;
  transition: all .3s;
  position: relative;
  width: 520px;
  height: 270px;
  margin: 24px;

  &.active {
    opacity: 1;
    transform: scale(1.1)
  }

  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1903px) {
  }
  @media (max-width: 1444px) {
  }
  @media (max-width: 959px) {
    width: 400px;
    height: 240px;
  }
  @media (max-width: 599px) {
    margin: 10px;
    width: 320px;
    height: 180px;
  }
  @media (max-width: 475px) {
    width: 220px;
    height: 120px;
  }
}

.slider {
  padding: 16px;
  margin: 100px 0 42px;
  @media (max-width: 1903px) {
    margin: 60px 0 24px;
  }
  @media (max-width: 959px) {
    margin: 18px 0;
  }
  @media (max-width: 599px) {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    max-width: 100vw;
    padding: 10px;
  }
}

.btn {
  width: 320px;
  margin: 0 auto;
  @media (max-width: 475px) {
    width: 100%;
  }
}

.popup {
  &__body {

    padding: 0 24px 24px;

    h3 {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 12px;
    }

    .text {
      font-size: 16px;
      text-align: center;
    }
  }
}

@media (max-width: 540px) {
  .popup {
    &__body {

      padding: 0 12px 12px;

      h3 {
        font-size: 18px;
      }

      .text {
        font-size: 14px;
      }
    }
  }
}
</style>

<template>
  <div class="view-page" style="padding: 0 !important;">
    <div class="content" v-if="show">
      <tui-image-editor ref="editor" :include-ui="useDefaultUI" :options="options"></tui-image-editor>
      <v-row class="mt-1 justify-center">
        <v-col cols="3">
          <v-btn x-small color="primary" block @click="photoDownload">Скачать</v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn x-small color="primary" outlined block @click="fs">Во весь экран</v-btn>
        </v-col>
      </v-row>
      <v-btn
          v-if="fullScreen"
          fab
          x-small
          class="closefs"
          color="white"
          outlined
          @click="fs"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
    <div class="no-editor" v-else>
      <Subtitle subtitle="- Фоторедактор доступен только с компьютера или&nbspноутбука -"/>
    </div>
  </div>
</template>

<script>
import TitlteH1 from "@/components/typography/TitlteH1";
import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import {ImageEditor} from '@toast-ui/vue-image-editor';
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {saveAs} from '@/assets/js/libs/FileSaver'
import Subtitle from "@/components/typography/Subtitle";

export default {
  name: "Editor",
  components: {Subtitle, TitlteH1, 'tui-image-editor': ImageEditor},

  data() {
    return {
      fullScreen: false,
      show: true,
      useDefaultUI: true,
      options: {
        // for tui-image-editor component's "options" prop
        usageStatistics: false,
        includeUI: {
          loadImage: {
            path: require('@/assets/img/main-logo.png'),
            name: 'SampleImage'
          },
          locale: {
            'Crop': 'Обрезать',
            'DeleteAll': 'Удалить всё',
            'Load': 'Загрузить',
            'Download': 'Сохранить',
            'Apply': 'Применить',
            'ZoomIn': 'Увеличить',
            'ZoomOut': 'Уменьшить',
            'Hand': 'Рука',
            'Undo': 'Назад',
            'Redo': 'Вперед',
            'Reset': 'Сбросить',
            'Custom': 'Ручное',
            'Cancel': 'Отменить',
            'Square': 'Квадрат',
            'Flip': 'Отразить',
            'Flip X': 'Горизонтально',
            'Flip Y': 'Вертикально',
            'Rotate': 'Повернуть',
            'Range': 'Диапазон',
            'Draw': 'Рисовать',
            'Free': 'Свободно',
            'Straight': 'Прямая',
            'Color': 'Цвет',
            'Shape': 'Фигуры',
            'Rectangle': 'Прямоугольник',
            'Circle': 'Круг',
            'Triangle': 'Треугольник',
            'Fill': 'Заполнение',
            'Stroke': 'Полоса',
            'Icon': 'Иконки',
            'Arrow': 'Стрелка',
            'Arrow-2': 'Стрелка',
            'Arrow-3': 'Стрелка',
            'Star': 'Звезда',
            'Star-2': 'Звезда',
            'Polygon': 'Многоугольник',
            'Location': 'Локация',
            'Heart': 'Сердце',
            'Bubble': 'Пузырь',
            'Custom icon': 'Своя иконка',
            'Text': 'Текст',
            'Bold': 'Жирный',
            'Italic': 'Курсив',
            'Underline': 'Подчеркнутый',
            'Left': 'Слева',
            'Center': 'По центру',
            'Right': 'Справа',
            'Text size': 'Размер шрифта',
            'Filter': 'Фильтры',
            'Grayscale': 'Серый',
            'Sepia': 'Сепия',
            'Sepia2': 'Сепия',
            'Blur': 'Размытие',
            'Emboss': 'Рельеф',
            'Invert': 'Инверсия',
            'Sharpen': 'Заострить',
            'Remove White': 'Удалить белый',
            'Brightness': 'Яркость',
            'Noise': 'Шум',
            'Pixelate': 'Пиксели',
            'Distance': 'Сила',
            'Threshold': 'Сила',
            'Tint': 'Оттенок',
            'Multiply': 'Усилить',
            'Blend': 'Смешать',
            'Color Filter': 'Фильтр цвета',
          },
          menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'filter'],

        },

      },
    };
  },
  methods: {
    async fetchPhoto() {
      this.setIsLoading(true)
      try {
        const response = await axios({
          baseURL: `${this.URL}/api/user/user_photos/editor/${this.$route.params.id}`,
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })

        let dataUrl = response.data;
        let blob = this.base64ToBlob(dataUrl)

        this.$refs.editor.invoke('loadImageFromFile', blob, 'name')
            .then(() => {

            })

      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    base64ToBlob(data) {

      let mimeString = '';
      let raw, uInt8Array, i, rawLength;

      raw = data.replace(/data:(image\/.+);base64,/, function (header, imageType) {
        mimeString = imageType;

        return '';
      });

      raw = atob(raw);
      rawLength = raw.length;
      uInt8Array = new Uint8Array(rawLength); // eslint-disable-line

      for (i = 0; i < rawLength; i += 1) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], {type: mimeString});
    },
    photoDownload() {

      let imageName = this.$refs.editor.invoke('getImageName');
      const dataURL = this.$refs.editor.invoke('toDataURL');

      let blob, type;

      blob = this.base64ToBlob(dataURL);
      type = blob.type.split('/')[1];
      if (imageName.split('.').pop() !== type) {
        imageName += '.' + type;
      }

      // Library: FileSaver - saveAs
      saveAs(blob, imageName); // eslint-disable-line
    },
    fs() {
      this.fullScreen = !this.fullScreen
      this.$refs.editor.$el.classList.toggle('fs')
    },
    ...mapActions(['setIsLoading'])
  },
  computed: {
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    ...mapGetters(['URL', 'token'])
  },
  mounted() {
    this.show = !this.mdAndDown;

    this.fetchPhoto();
  }
}
</script>

<style lang="scss">
.no-editor {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-button {
  background: transparent !important;
}

.closefs {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 3334;
}

.tui-image-editor-container {
  min-height: calc(100vh - 52px);

  &.fs {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3333;
    margin: 0 !important;
  }

}

.tui-image-editor-header-logo,
.tui-image-editor-header-buttons,
.tie-btn-history,
.tie-btn-delete,
.tie-btn-deleteAll,
.tui-image-editor-help-menu li:nth-child(9) {
  display: none !important;
}

.tui-image-editor-container .tui-image-editor-help-menu.top {
  width: auto;
  padding: 0 24px;
}

.tui-image-editor-submenu-item {

  li:last-child {
    .filter-color-item:nth-child(2),
    .filter-color-item:nth-child(3) {
      display: none !important;
    }
  }
}

@media (max-width: 890px) {
  .tui-image-editor-container .tui-image-editor-main.tui-image-editor-menu-filter .tui-image-editor-submenu > div.tui-image-editor-menu-filter {
    display: block;
    padding: 12px 5px;
  }
  .tui-image-editor-submenu-item {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;

    li {
      margin: 0 !important;
    }

    li:nth-child(2), li:nth-child(4 ), li:nth-child(6), {
      display: none !important;
    }

    li:last-child {
      width: 100%;
    }
  }
  .tui-image-editor-container .tui-image-editor-checkbox-wrap.fixed-width {
    width: 100%;
  }
  .tui-image-editor-container label {
    font-size: 12px;
  }
}
</style>

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/lib/locale/ru';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#4442D2',
        secondary: '#795DDA',
        accent: '#82B1FF',
        error: '#FF5252',
        success: '#4CAF50',
        yellow: '#FFC107',
        info: '#9780E7',
        white: '#fff',
        black: '#222',
        dark: '#A9A9A9',
        muted: '#818181',
        underline: '#929292',
        modalButton: '#233245',
        textColor: '#4C4C4C',
        textAddColor: '#9C9C9C',
        titleColor: '#222222',
        bgAddColor: '#F9F9F9',
        bgCard: '#F7F7F7',
        progressbarLine: '#DFDEF2',
        scrollColor: '#D7D7D7',
      },
    },
  },
  lang: {
    locales: {ru},
    current: 'ru',
  },
});
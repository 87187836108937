<template>
  <div class="view-page">
    <TitlteH1 title="Контакты" class="mb-4"/>
    <div class="content">
      <div class="text font-weight-bold">Реквизиты организации</div>
      <div class="text">Название: ИП Яковлев Антон Викторович</div>
      <div class="text">ИНН: 580505429043</div>
      <div class="text">ОГРНИП: 316583500103382</div>
      <div class="text">Расчетный счет: 40802810829170001034</div>
      <div class="text">Банк: ФИЛИАЛ «НИЖЕГОРОДСКИЙ» АО «АЛЬФА-БАНК»</div>
      <div class="text">БИК: 042202824</div>
      <div class="text">Корр. счет: 30101810200000000824</div>
      <div class="text">Юридический адрес: 442893, Пензенская обл, Сердобский р-н, г Сердобск, ул Тюрина, д 3, кв 78
      </div>
      <div class="text">Фактический адрес: 442893, Пензенская обл, Сердобский р-н, г Сердобск, ул Тюрина, д 3, кв 78
      </div>

      <div class="content">
        <div class="text font-weight-bold">Обратная связь</div>
        <div class="text font-weight-medium">Для звонков (с понедельника по пятницу с 10.00 до 18.00 по московскому времени):</div>
        <div class="text"><a href="tel:+79093177466">+7 (909) 317-74-66</a></div>
        <div class="text font-weight-medium">Для сообщений на почту, Telegram или WhatsApp: (доступно в любое время суток):</div>
        <div class="text"><a href="mailto:vnevremeni@internet.ru">Почта: vnevremeni@internet.ru</a></div>
        <div class="text"><a href="https://t.me/+79093177466" target="_blank">Telegram: +7 (909) 317-74-66</a></div>
        <div class="text"><a href="https://wa.me/79093177466?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%D0%9F%D0%B8%D1%88%D1%83%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0%20phototime.pro" target="_blank">WhatsApp: +7 (909) 317-74-66</a></div>
      </div>

    </div>
  </div>
</template>


<script>
import TitlteH1 from "@/components/typography/TitlteH1.vue";

export default {
  name: "Contacts",
  components: {TitlteH1}
}
</script>

<style lang="scss" scoped>
.text {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;

  a {
    display: flex;
    flex: 0 0 100%;
    color: #222222;
  }
}
</style>

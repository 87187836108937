<template>
  <div class="drawer">
    <div class="user">
      <div class="user-photo">
        <v-img src="@/assets/img/user.png"/>
      </div>
      <div class="user-name" v-if="$store.getters.user">
        {{ $store.getters.user.phone?.replace(/(.)(...)(...)(..)(..)/, '+$1 ($2) $3-$4-$5') }}
        <span v-if="$store.getters.user.firstName">{{ $store.getters.user.firstName }}</span>
        <span v-else>Клиент</span>
      </div>
    </div>
    <v-list
        nav
    >
      <v-list-item
          v-for="link in links"
          :key="link.title"
          :class="[{'drawer-link-active': $route.name === link.route}]"
          @click="to(link.route)"
      >
        <v-list-item-icon class="mr-4">
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ link.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          :class="[{'drawer-link-active': $route.name === 'cart'}]"
          @click="to('cart')"
      >
        <v-list-item-icon class="mr-4">
          <v-badge
              :content="user?.cart?.length"
              :value="user?.cart?.length"
              color="primary"
              offset-x="10"
              offset-y="7"
              overlap
              transition="fade"
          >
            <v-icon>mdi-cart-outline</v-icon>
          </v-badge>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Корзина</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          :class="[{'drawer-link-active': $route.name === 'settings'}]"
          @click="to('settings')"
      >
        <v-list-item-icon class="mr-4">
          <v-icon>mdi-cog-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Настройки профиля</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          :class="[{'drawer-link-active': $route.name === 'contacts'}]"
          @click="to('contacts')"
      >
        <v-list-item-icon class="mr-4">
          <v-icon>mdi-information-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Контакты</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="logoutUser" class="mt-16">
        <v-list-item-icon class="mr-4">
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Выйти</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Drawer",
  data() {
    return {
      links: [
        {title: 'Искать фото', icon: 'mdi-magnify-expand', route: 'dateSearch'},
        {title: 'Мои фото', icon: 'mdi-image-multiple', route: 'photo'},
        {title: 'Избранное', icon: 'mdi-cards-heart-outline', route: 'bookmarks'},
      ]
    }
  },
  methods: {
    to(route) {
      this.$router.push({name: route})
    },
    ...mapActions(['logoutUser'])
  },
  computed :{
    ...mapGetters(['user'])
  }
}
</script>

<style lang="scss" scoped>
.drawer-link-active {
  background: rgba(121, 93, 218, .2);
}

.theme--light.v-navigation-drawer {
  box-shadow: 0 4px 40px rgba(77, 93, 113, 0.1);
}

.user {
  padding: 48px 8px 34px;
  display: flex;
  gap: 7px;
  align-items: center;
}

.user-photo {
  width: 64px;
  height: 64px;
  @media (max-width: 599px) {
    width: 48px;
    height: 48px;
  }
}

.user-name {
  padding: 10px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.1px;
  color: #222222;

  span {
    font-weight: 400;
    font-size: 16px;
    color: #A9A9A9;
    display: block;
    margin-top: 10px;
  }

  @media (max-width: 599px) {
    font-size: 14px;
    span {
      font-size: 14px;
      margin-top: 2px;
    }
  }
}
</style>

<template>
  <div class="view-page">
    <TitlteH1 title="Избранное"/>
    <Subtitle subtitle="Быстрый доступ к понравившимся фотографиям"/>
    <GalleryBtns bookmarks @cart="clickCart" @fav="clickBookmark" class="mt-8" :gallery="returnGallery"/>
    <div
        v-intersect="next"
        class="observer"
    >

    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TitlteH1 from "@/components/typography/TitlteH1";
import Subtitle from "@/components/typography/Subtitle";
import VGallery from "@/components/gallery/VGallery";
import axios from "axios";
import store from "@/store";
import GalleryBtns from "@/components/gallery/GalleryBtns.vue";


export default {
  name: "Bookmarks",
  components: {GalleryBtns, VGallery, Subtitle, TitlteH1},
  data() {
    return {
      gallery: [],
      page: 0,
      total: 1,
      returnGallery: [],
      canLoad: true,
    }
  },
  methods: {
    async next(e, i) {
      if (e[0].isIntersecting && this.total > this.gallery.length && this.canLoad) {
        this.canLoad = false
        this.page++;
        await this.getAllPhotos()
      }
    },
    async getAllPhotos() {
      //получаем все фото что есть при первой загрузке страницы
      this.setIsLoading(true)
      this.changeAlertType('info');
      this.changeAlertMessage(
          `<span style="text-align: center; display: block" >
Загрузка фотографий, чуть-чуть подождите           </span>`
      );
      try {
        const response = await axios({
          baseURL: `${this.URL}/api/user/user_bookmarks?page=${this.page}&itemsPerPage=24`,
          headers: {
            'Accept': 'application/ld+json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })
        if (response.data['hydra:member'].length === 0) {
          this.gallery = [];
        } else {
          this.gallery = [...this.gallery, ...response.data['hydra:member']];
          this.canLoad = true;
        }
        this.total = response.data['hydra:totalItems'];
        this.mapGallery()
        setTimeout(() => {
          this.setIsAlert(false)
        }, 3000)
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    clickBookmark(img) {
      //клик по сердечку. сверяем id кликнутой фотки с вхождением в массив закладок
      //если есть, то удаляем, если нет то добавляем
      let idx = this.bookmarksArray.indexOf(img.id)
      if (idx !== -1) {
        const photo = this.bookmarksArrayWithId.find(i => i.photo === img.id)
        this.deleteBookmark({string: photo.id})
        this.bookmarksArray.splice(idx, 1)
        this.gallery = this.gallery.filter(item => item.id !== img.photoId)
      } else {
        this.bookmarksArray.push(img.id);
        this.addBookmark({string: img['@id']})

      }
      this.mapGallery()
    },
    async clickCart(img) {
      //клик по корзине. сверяем id кликнутой фотки с вхождением в массив закладок
      //если есть, то удаляем, если нет то добавляем
      let idx = this.cartArray.indexOf(img.id)
      if (idx !== -1) {
        this.setIsAlert(true);
        this.changeAlertMessage('<span style="text-align: center; display: block" >Фото уже в корзине</span>');
        this.changeAlertType('success')
        setTimeout(() => {
          this.setIsAlert(false);
        }, 1500)
        return
        // const photo = this.cartArrayWithId.find(i => i.photo === img.id)
        // this.deleteFromCart({string: photo.id})
        // this.cartArray.splice(idx, 1)
      } else {
        if (this.user.cartCount >= 100) {
          this.changeAlertType('error')
          this.setIsAlert(true)
          this.changeAlertMessage('За один раз возможно купить не более 100 фотографий')
          setTimeout(() => {
            this.setIsAlert(false)
          }, 3000)
          return
        }
        this.setIsAlert(true);
        this.changeAlertMessage('<span style="text-align: center; display: block" >Фото добавлено в корзину</span>');
        this.changeAlertType('success')
        setTimeout(() => {
          this.setIsAlert(false);
        }, 1500)
        this.cartArray.push(img.id);
        this.addToCart({string: img['@id']})

      }
      this.mapGallery()
    },
    mapGallery() {
      //Вводим в галерею новые поля. Избранное и корзина.
      //Основываемся на массиве айдишников получаемых в юзер инфо
      this.returnGallery = this.gallery.map(i => {
        return {
          ...i.photo,
          photoId: i.id,
          bookmarks: this.bookmarksArray.indexOf(i.photo.id) !== -1,
          cart: this.cartArray.indexOf(i.photo.id) !== -1
        };
      }).reverse()
    },
    ...mapActions(['setIsLoading', 'setIsAlert', 'changeAlertMessage', 'changeAlertType', 'addBookmark', 'deleteBookmark', 'addToCart', 'deleteFromCart'])
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs
    },

    disabledLoad() {
      return this.total === this.gallery.length
    },
    bookmarksArray() {
      return this.user.bookmarks.map(i => +i.photo.replace(/\D/g, ''))
    },
    bookmarksArrayWithId() {
      return this.user.bookmarks.map(i => {
        return {
          id: i.id,
          photo: +i.photo.replace(/\D/g, '')
        }
      })
    },
    cartArray() {
      return this.user.cart.map(i => +i.photo.replace(/\D/g, ''))
    },
    cartArrayWithId() {
      return this.user.cart.map(i => {
        return {
          id: i.id,
          photo: +i.photo.replace(/\D/g, '')
        }
      })
    },
    ...mapGetters(['token', 'user', 'URL'])
  },
  async mounted() {

  }
}
</script>

<style scoped>

</style>

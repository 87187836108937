<template>
  <div class="template">
    <!-- галерея с попапом -->
    <div id="lightgallery" v-if="gallery.length > 0">
      <div
          class="image gallery-item"
          v-for="img in mappedGallery"
          :key="img.id"
          @click="showImg(img)"
      >
        <v-img :src="URL + img.small"/>
        <div class="btns">
          <v-tooltip
              @click.stop.prevent="reUploadImg(img)"
              v-if="reUpload && img.status === 'rejected'"
              top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-if="reUpload && img.status === 'rejected'"
                  @click.stop.prevent="reUploadImg(img)"
                  v-bind="attrs"
                  v-on="on"
                  small
                  fab
                  class="btn"
              >
                <v-icon color="primary">
                  mdi-progress-upload
                </v-icon>
              </v-btn>

            </template>
            <span>ЗАМЕНА ФОТОГРАФИИ</span>
          </v-tooltip>
          <v-btn
              class="btn"
              fab
              small
              @click.stop.prevent="downloadImage(img)"
              v-if="reUpload && img.status === 'rejected'"
          >
            <v-icon color="primary">
              mdi-download
            </v-icon>
          </v-btn>
          <v-btn
              class="btn"
              fab
              small
              @click.stop.prevent="del(img.id)"
              v-if="hasDelete"
          >
            <v-icon>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
          <v-btn
              class="btn"
              fab
              small
              @click.stop.prevent="cart(img)"
              v-if="search || bookmarks || cartUser"
          >
            <v-icon color="primary" v-if="img.cart">
              mdi-cart-check
            </v-icon>
            <v-icon v-else>
              mdi-cart-arrow-down
            </v-icon>
          </v-btn>
          <v-btn
              class="btn"
              fab
              small
              @click.stop.prevent="fav(img)"
              v-if="search || bookmarks"
          >
            <v-icon color="error" v-if="img.bookmarks">
              mdi-cards-heart
            </v-icon>
            <v-icon v-else>
              mdi-cards-heart-outline
            </v-icon>
          </v-btn>
          <v-btn
              class="btn"
              fab
              small
              @click.stop.prevent="edit(img.id)"
              v-if="editor"
          >
            <v-icon color="primary">
              mdi-circle-edit-outline
            </v-icon>
          </v-btn>
          <v-btn
              class="btn"
              fab
              small
              @click.stop.prevent="downloadImage(img)"
              v-if="editor"
          >
            <v-icon color="primary">
              mdi-download
            </v-icon>
          </v-btn>
          <span class="status" v-if="status">
            <v-icon color="primary" v-if="img.status === 'waiting'">mdi-timer-sand</v-icon>
            <v-icon color="primary" v-if="img.status === 'approved'">mdi-check-circle</v-icon>
            <v-tooltip @click.stop.prevent v-if="img.status === 'rejected'" top>
              <template v-slot:activator="{ on, attrs }">
               <v-icon
                   v-bind="attrs"
                   v-on="on"
                   color="primary"
                   @click.stop.prevent
               >
                 mdi-close-circle-outline</v-icon>
              </template>
               <span>{{ returnRus(img.typeRejection) }}</span>
            </v-tooltip>

          </span>
          <!--          <span class="price" v-if="price">-->
          <!--            <v-chip small>{{ img.price }} ₽</v-chip>-->
          <!--          </span>-->

        </div>
        <span class="rejected" v-if="img.status === 'rejected'">{{ returnRus(img.typeRejection) }}</span>
      </div>
    </div>
    <div class="empty" v-else>- Список пуст -</div>
    <transition name="fade">
      <div v-if="show" class="popup" @click.self="closePopup">
        <div class="popup-close">
          <v-btn
              class="btn"
              icon
              small
              @click="closePopup"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <img loading="lazy" class="popup-img" :src="URL+selectedImg.small" alt="">
        <div class="popup-btns" v-if="mobile">
          <v-btn
              class="btn"
              fab
              small
              @click.stop.prevent="downloadImage(selectedImg)"
              v-if="reUpload && selectedImg.status === 'rejected'"
          >
            <v-icon color="primary">
              mdi-download
            </v-icon>
          </v-btn>
          <v-btn
              class="btn"
              fab
              small
              @click.stop.prevent="reUploadImg(selectedImg)"
              v-if="reUpload && selectedImg.status === 'rejected'"
          >
            <v-icon color="primary">
              mdi-progress-upload
            </v-icon>
          </v-btn>
          <v-btn
              class="btn"
              fab
              small
              @click.stop.prevent="fav(selectedImg)"
              v-if="search || bookmarks"
          >
            <v-icon color="error" v-if="selectedImg.bookmarks">
              mdi-cards-heart
            </v-icon>
            <v-icon v-else>
              mdi-cards-heart-outline
            </v-icon>
          </v-btn>
          <v-btn
              class="btn"
              fab
              small
              @click.stop.prevent="cart(selectedImg)"
              v-if="search || bookmarks || cartUser"
          >
            <v-icon color="primary" v-if="selectedImg.cart">
              mdi-cart-check
            </v-icon>
            <v-icon v-else>
              mdi-cart-arrow-down
            </v-icon>
          </v-btn>
          <v-btn
              class="btn"
              fab
              small
              @click.stop.prevent="edit(selectedImg.id)"
              v-if="editor"
          >
            <v-icon color="primary">
              mdi-circle-edit-outline
            </v-icon>
          </v-btn>
          <v-btn
              class="btn"
              fab
              small
              @click.stop.prevent="downloadImage(selectedImg)"
              v-if="editor"
          >
            <v-icon color="primary">
              mdi-download
            </v-icon>
          </v-btn>
          <v-btn
              class="btn"
              fab
              small
              @click.stop.prevent="del(selectedImg.id)"
              v-if="hasDelete"
          >
            <v-icon>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </div>
        <div class="popup-btns" v-else>
          <v-btn
              class="btn"
              small
              @click.stop.prevent="downloadImage(selectedImg)"
              v-if="reUpload && selectedImg.status === 'rejected'"
          >
            <v-icon color="primary">
              mdi-download
            </v-icon>
            Скачать
          </v-btn>
          <v-btn
              class="btn"
              small
              @click.stop.prevent="reUploadImg(selectedImg)"
              v-if="reUpload && selectedImg.status === 'rejected'"
          >
            <v-icon color="primary">
              mdi-progress-upload
            </v-icon>
            Обновить
          </v-btn>
          <v-btn
              class="btn"
              small
              @click.stop.prevent="fav(selectedImg)"
              v-if="search || bookmarks"
          >
            <v-icon color="error" v-if="selectedImg.bookmarks" style="margin-right: 4px;">
              mdi-cards-heart
            </v-icon>
            <v-icon color="primary" v-else style="margin-right: 4px;">
              mdi-cards-heart-outline
            </v-icon>
            {{ selectedImg.bookmarks ? 'В избранном' : 'В избранное' }}
          </v-btn>
          <v-btn
              class="btn"
              small
              @click.stop.prevent="cart(selectedImg)"
              v-if="search || bookmarks || cartUser"
          >
            <v-icon color="primary" v-if="selectedImg.cart" style="margin-right: 4px;">
              mdi-cart-check
            </v-icon>
            <v-icon color="primary" v-else style="margin-right: 4px;">
              mdi-cart-arrow-down
            </v-icon>
            {{ selectedImg.cart ? 'В корзине' : 'В корзину' }}
          </v-btn>
          <v-btn
              class="btn"
              small
              @click.stop.prevent="edit(selectedImg.id)"
              v-if="editor"
          >
            <v-icon color="primary" style="margin-right: 4px;">
              mdi-circle-edit-outline
            </v-icon>
            Редактировать
          </v-btn>
          <v-btn
              class="btn"
              small
              @click.stop.prevent="downloadImage(selectedImg)"
              v-if="editor"
          >
            <v-icon color="primary" style="margin-right: 4px;">
              mdi-download
            </v-icon>
            Скачать
          </v-btn>
          <v-btn
              class="btn"
              small
              @click.stop.prevent="del(selectedImg.id)"
              v-if="hasDelete"
          >
            <v-icon color="primary" style="margin-right: 4px">
              mdi-trash-can-outline
            </v-icon>
            Удалить
          </v-btn>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {saveAs} from 'file-saver';

export default {

  name: "GalleryBtns",
  props: {
    gallery: {
      type: Array,
      required: true,
    },
    hasDelete: {
      type: Boolean,
      default: false
    },
    reUpload: {
      type: Boolean,
      default: false
    },
    status: {
      type: Boolean,
      default: false
    },
    description: {
      type: Boolean,
      default: false
    },
    price: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    },
    bookmarks: {
      type: Boolean,
      default: false
    },
    editor: {
      type: Boolean,
      default: false
    },
    cartUser: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedImg: {},
      show: false,
      scrollY: 0,
    }
  },

  computed: {
    mappedGallery() {
      return this.gallery
    },
    mobile() {
      return this.$vuetify.breakpoint.xs
    },
    ...mapGetters(['URL'])
  },
  methods: {
    downloadImage(img) {
      // Формируем URL изображения
      const imageUrl = this.URL + img.full;

      // Извлекаем имя файла из URL (если оно доступно)
      const fileName = imageUrl.split('/').pop();

      this.setIsLoading(true);
      try {
        saveAs(imageUrl, fileName);
      } catch (e) {
      } finally {
        this.setIsLoading(false);
      }
    },
    returnRus(name) {
      let msg;
      switch (name) {
        case 'framing':
          msg = 'Кадрирование'
          break
        case 'composition':
          msg = 'Композиция (Завален горизонт, много пространства сверху/снизу, не соблюдены правила)'
          break
        case 'light_darkness':
          msg = 'Засвет/Темнота'
          break
        case 'white_balance':
          msg = 'Нарушен баланс белого'
          break
        case 'focus':
          msg = 'Нет фокуса'
          break
        case 'extra_in_frame':
          msg = 'Лишнее в кадре (части тела, предметы)'
          break
        case 'red_eyes':
          msg = 'Красные глаза'
          break
        default:
          msg = 'Уточните у администратора'
          break
      }
      return msg
    },
    reUploadImg(img) {
      this.$emit('reUploadImg', img)
      this.closePopup()
    },
    del(id) {
      this.$emit('delete', id)
      this.closePopup()
    },
    fav(id) {
      this.$emit('fav', id)
      if (this.selectedImg) {
        this.selectedImg.bookmarks = !this.selectedImg.bookmarks
      }
    },
    cart(id) {
      this.$emit('cart', id)
      if (this.selectedImg) {
        if (this.$route.name === 'cart' && this.selectedImg.cart) {
          this.closePopup()
        } else {
          this.selectedImg.cart = true;
        }
      }
    },
    edit(id) {
      this.$emit('edit', id)
      this.closePopup()
    },
    // download(id) {
    //   this.$emit('download', id)
    //   this.closePopup()
    // },
    showImg(img) {
      this.selectedImg = img;
      this.show = true;
      this.scrollY = window.scrollY;
      const body = document.querySelector('body');
      body.classList.add('lock');
    },
    closePopup() {
      const body = document.querySelector('body');
      body.classList.remove('lock');
      window.scrollTo(0, this.scrollY);
      this.selectedImg = null;
      this.show = false;
    },

    ...mapActions(['setIsLoading'])
  }
}
</script>

<style lang="scss" scoped>
/*Стили галерии общие вынесены в main.scss*/

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btns {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 4px;
}

.status {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.price {
  text-decoration: none;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-close {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 33;
}

.popup-img {
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  opacity: 1;
}

.popup-btns {
  position: absolute;
  bottom: 12px;
  right: 12px;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 12px;
}

.rejected {
  font-size: 13px;
  color: #222;
  position: absolute;
  bottom: 2px;
  left: 2px;
  padding: 4px;
  background: #fff;
  border-radius: 4px;
}
</style>

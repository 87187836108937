import Vue from 'vue'
import Vuex from 'vuex'
import loader from "./loader";
import URLS from "./URLS";
import user from "./user/user";
import alert from "./alert";
import photoLocations from "./user/photoLocations";
import userBookmarks from "./user/userBookmarks";
import userCart from "./user/userCart";
import keyYoukassa from "./user/keyYoukassa";

Vue.use(Vuex)

const modules = {
  loader,
  URLS,
  user,
  alert,
  photoLocations,
  userBookmarks,
  userCart,
  keyYoukassa
}
const store = new Vuex.Store({
  modules,
});

export default store;
<template>
  <div class="main">
    <header class="header">
      <v-container>
        <div class="logo">
          <v-img src="@/assets/img/main-logo.png"/>
        </div>
        <v-spacer/>
        <div class="btn">
          <v-btn
            color="black"
            outlined
            block
            :small="mobile"
          >
            <a target="_blank" href="https://get.phototime.pro">
            Сотрудничество
            </a>
          </v-btn>
        </div>
      </v-container>
    </header>
    <div class="page">
      <v-container>
<!--        <div class="page-subtitle">Сохрани момент — сделай фото</div>-->
        <h1 class="page-title">PHOTOTIME</h1>
        <div class="page-description">
          Phototime представляет собой ведущую федеральную сеть для фотоуслуг в России, Беларуси и Казахстане.
        </div>
        <div class="page-description mt-3">
          Наши онлайн-сервисы делают наилучшие моменты доступными: просто войдите, укажите дату и место события, сохраните понравившиеся фотографии.
        </div>
        <div class="page-btn">
          <v-btn
            color="yellow"
            block
            :small="mobile"
            @click="$router.push({name: 'photo'})"
            v-if="isAuth"
          >
            Открыть ЛК
          </v-btn>
          <v-btn
            color="yellow"
            block
            :small="mobile"
            @click="showAuth"
            v-else
          >
            Войти
          </v-btn>
        </div>
        <div class="page-img">
          <v-img src="@/assets/img/main-bg.png"/>
        </div>
      </v-container>
    </div>
    <footer class="footer">
      <v-container>
        <div class="footer-copy">© 2021 Phototime.</div>
        <v-spacer/>
        <a href="#" class="footer-policy">Политика конфиденциальности</a>
      </v-container>
    </footer>
    <v-dialog
      transition="dialog-top-transition"
      max-width="540"
      v-model="isShow"
      :fullscreen="mobile"
    >
      <auth-dialog @close="isShow = false" @resetBtn="showReset"/>
    </v-dialog>
    <v-dialog
      max-width="540"
      v-model="isShowReset"
      :fullscreen="mobile"
    >
      <reset-dialog @close="isShowReset = false" @resetSuccess="resetSuccess"/>
    </v-dialog>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import AuthDialog from "@/components/dialogs/auth/AuthDialog";
import ResetDialog from "@/components/dialogs/resetPassword/ResetDialog";

export default {
  name: "MainPage",
  components: {ResetDialog, AuthDialog},
  data() {
    return {
      isShow: false,
      isShowReset: false,
    }
  },
  methods: {
    showAuth() {
      this.isShow = true;
    },
    showReset() {
      this.isShow = false;
      this.isShowReset = true;
    },
    resetSuccess() {
      this.isShowReset = false;
      this.isShow = true;
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs
    },
    ...mapGetters(['isAuth'])
  },
  mounted() {
    if (this.$route.query.needAuth || this.$route.query.redirect) {
      this.isShow = true
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/pages/main-page.scss";
.btn a {
  text-decoration: none;
  color: #222;
}
</style>

<template>
  <div class="view-page">
    <TitlteH1 title="Корзина"/>
    <Subtitle v-if="discountList" :subtitle="'Система скидок: ' + discountLevelsString"/>
    <div v-if="user?.cartCount > 0">
      <Subtitle
          v-if="discount"
          :subtitle="
      'Фотографий в корзине: '+user?.cartCount+
      '. На сумму: <span ' +
       'style=text-decoration:line-through;color:red>'+totalPrice+
      '</span> ' +
       '<span style=font-weight:700>'+discountPrice +'</span> ₽'
        "
      />
      <Subtitle
          v-else
          :subtitle="'Фотографий в корзине: '+user?.cartCount+'. На сумму: '+ totalPrice+ '₽'"
      />
    </div>
    <v-row v-if="user?.cartCount > 0" class="mt-4">
      <v-col cols="12">
        <v-btn
            :small="mobile"
            type="submit"
            color="primary"
            block
            @click="pay"
            :loading="isLoading"
        >
          Оплатить
        </v-btn>
      </v-col>
    </v-row>
    <GalleryBtns cartUser price @cart="clickCart" class="mt-8" :gallery="returnGallery"/>
<!--    <div-->
<!--        v-intersect="next"-->
<!--        class="observer"-->
<!--    >-->

<!--    </div>-->
    <v-row v-if="this.gallery.length === 0" class="mt-4 justify-center">
      <v-col cols="12" lg="3">
        <v-btn
            :small="mobile"
            color="primary"
            block
            @click="$router.push({name: 'dateSearch'})"
        >
          Найти фото
        </v-btn>
      </v-col>
      <v-col cols="12" lg="3">
        <v-btn
            :small="mobile"
            color="primary"
            outlined
            block
            @click="$router.push({name: 'bookmarks'})"
        >
          Избранное
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TitlteH1 from "@/components/typography/TitlteH1";
import Subtitle from "@/components/typography/Subtitle";
import VGallery from "@/components/gallery/VGallery";
import axios from "axios";
import store from "@/store";
import GalleryBtns from "@/components/gallery/GalleryBtns.vue";


export default {
  name: "Cart",
  components: {GalleryBtns, VGallery, Subtitle, TitlteH1},
  data() {
    return {
      gallery: [],
      page: 1,
      total: 1,
      returnGallery: [],
      // discount: null,
      discountPrice: null,
      discountList: null,
      canLoad: true,
    }
  },
  methods: {
    async next(e, i) {
      if (e[0].isIntersecting && this.total > this.gallery.length && this.canLoad) {
        // this.canLoad = false
        // this.page++;
        await this.getAllPhotos()
      }

    },
    async getAllPhotos() {
      //получаем все фото что есть при первой загрузке страницы
      this.setIsLoading(true)
      this.changeAlertType('info');
      this.changeAlertMessage(
          `<span style="text-align: center; display: block" >
Загрузка фотографий, чуть-чуть подождите           </span>`
      );
      try {
        const response = await axios({
          baseURL: `${this.URL}/api/user/user_cart?page=${this.page}&itemsPerPage=100`,
          headers: {
            'Accept': 'application/ld+json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })
        if (response.data['hydra:member'].length === 0) {
          this.gallery = [];
        } else {
          this.gallery = [...this.gallery, ...response.data['hydra:member']];
          this.canLoad = true;
        }
        this.total = response.data['hydra:totalItems'];
        this.mapGallery()
        setTimeout(() => {
          this.setIsAlert(false)
        }, 3000)
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading(false)
      }
    },
    async clickCart(img) {
      //клик по корзине. сверяем id кликнутой фотки с вхождением в массив закладок
      //если есть, то удаляем, если нет то добавляем
      let idx = this.cartArray.indexOf(img.id)
      if (idx !== -1) {
        const photo = this.cartArrayWithId.find(i => i.photo === img.id)
        await this.deleteFromCart({string: photo.id})
        await this.getDiscount();
        this.setIsAlert(true);
        this.changeAlertMessage('<span style="text-align: center; display: block" >Фотография удалена из корзины</span>');
        this.changeAlertType('error')
        setTimeout(() => {
          this.setIsAlert(false);
        }, 1500)
        this.cartArray.splice(idx, 1)
        this.gallery = [];
        await this.loginUser({token: this.token})
        await this.getAllPhotos()
      } else {
        if (this.user.cartCount >= 100) {
          this.changeAlertType('error')
          this.setIsAlert(true)
          this.changeAlertMessage('Более 100 фотографий добавить в корзину нельзя, воспользуйтесь сохранением в "Избранное"')
          setTimeout(() => {
            this.setIsAlert(false)
          }, 3000)
          return
        }
        this.setIsAlert(true);
        this.changeAlertMessage('<span style="text-align: center; display: block" >Фотография добавлена в корзину</span>');
        this.changeAlertType('success')
        setTimeout(() => {
          this.setIsAlert(false);
        }, 1500)
        this.cartArray.push(img.id);
        await this.addToCart({string: img['@id']})

      }
      this.mapGallery()
    },
    mapGallery() {
      //Вводим в галерею новые поля. Избранное и корщина.
      //Основываемся на массиве айдишников получаемых в юзер инфо
      this.returnGallery = this.gallery.map(i => {
        return {
          ...i.photo,
          photoId: i.id,
          cart: this.cartArray.indexOf(i.photo.id) !== -1,
          price: i.photo.album.albumLocation.location.priceOnline
        };
      }).reverse()
    },
    async pay() {
      this.setIsLoading(true)
      try {
        this.changeAlertType('info')
        this.setIsAlert(true)
        this.changeAlertMessage('<span style="display: block; text-align: center"> Идет процесс генерации ссылки на оплату<br>Пожалуйста, не закрывайте страницу</span>')

        const response = await axios({
          baseURL: `${this.URL}/api/user/payments`,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'POST',
          data: JSON.stringify({
            photoItemsId: this.cartArray,
            cartItemsId: this.cartIds,
          })
        })
        if (response.data.status === 'ok') {
          this.setIsAlert(false)
          const redirect = response.data.redirect
          window.open(redirect, '_self');
        } else {
          this.setIsAlert(true);
          this.changeAlertType('error')
          this.changeAlertMessage(response.data.message)
          setTimeout(() => {
            this.setIsAlert(false);
          }, 3000)
        }

      } catch (e) {
        this.setIsAlert(true);
        this.changeAlertType('error')
        this.changeAlertMessage(`<span style=" display: block; text-align: center">Во время оплаты произошла ошибка. <br> Попробуйте позже, или обратитесть к администратору </span>`)
        setTimeout(() => {
          this.setIsAlert(false);
        }, 10000)
      } finally {
        this.setIsLoading(false)
      }
    },
    async getDiscount() {
      // получаем процент скидки
      try {
        const response = await axios({
          baseURL: `${this.URL}/api/user/user_cart_discount`,
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.token}`
          },
          method: 'GET',
        })
        this.discountList = response.data.slice(1);
      } catch (e) {
        console.log(e)
      } finally {
      }
    },

    ...
        mapActions(['loginUser','setIsLoading', 'setIsAlert', 'changeAlertMessage', 'changeAlertType', 'addToCart', 'deleteFromCart'])
  },
  computed: {
    discount () {
      return this.discountList.filter(item => {
        if (this.user.cartCount > item.min && this.user.cartCount <= item.max) {
          return item
        }
      })[0]
    },
    mobile() {
      return this.$vuetify.breakpoint.xs
    },
    discountLevelsString() {
      let arr = this.discountList.map(item => {
        return `${item.min + 1}-${item.max} = ${item.discount}%`
      })
      return arr.join(', ')
    },
    disabledLoad() {
      return this.total === this.gallery.length
    },
    cartArray() {
      return this.user.cart.map(i => +i.photo.replace(/\D/g, ''))
    },
    cartIds() {
      return this.user.cart.map(i => i.id)
    },
    cartArrayWithId() {
      return this.user.cart.map(i => {
        return {
          id: i.id,
          photo: +i.photo.replace(/\D/g, '')
        }
      })
    },
    totalPrice() {
      let total = 0;
      this.returnGallery.forEach(i => {
        total += i.price;
      })
      if (this.discount?.discount > 0) {
        this.discountPrice = total - (total / 100 * this.discount.discount)
      }
      return total
    },

    ...mapGetters(['token', 'user', 'URL', 'isLoading'])
  },
  async mounted() {
    await this.loginUser({token: this.token})
    await this.getDiscount();
    await this.getAllPhotos()
  },
}
</script>

<style scoped>

</style>

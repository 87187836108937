<template>
  <v-bottom-navigation class="bottom-panel">
    <v-sheet
        v-for="link in links"
        :key="link.title"
        :class="[{'drawer-link-active': $route.name === link.route}, 'bottom-panel-item']"
        @click="to(link.route)"
    >
      <v-icon>{{ link.icon }}</v-icon>
    </v-sheet>
  </v-bottom-navigation>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BottomPanel",
  data() {
    return {
      links: [
        { title: 'Искать фото', icon: 'mdi-magnify-expand', route: 'dateSearch' },
        { title: 'Мои фото', icon: 'mdi-image-multiple', route: 'photo' },
        { title: 'Избранное', icon: 'mdi-cards-heart-outline', route: 'bookmarks' },
        { title: 'Корзина', icon: 'mdi-cart-outline', route: 'cart' },
        { title: 'Настройки профиля', icon: 'mdi-cog-outline', route: 'settings' },
        { title: 'Контакты', icon: 'mdi-information-outline', route: 'contacts' },
        { title: 'Выйти', icon: 'mdi-logout', route: null }
      ]
    };
  },
  methods: {
    to(route) {
      if (route) {
        this.$router.push({ name: route });
      } else {
        this.logoutUser();
      }
    },
    ...mapActions(['logoutUser'])
  },
  computed: {
    ...mapGetters(['user'])
  }
};
</script>

<style lang="scss" scoped>
.bottom-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
}

.bottom-panel-item {
  border-radius: 5px; /* Добавляем бордер радиус */
  padding: 5px;
  background-color: #f0f0f0;
}

/* Стили для выделения активной ссылки */
.drawer-link-active {
  background: rgba(121, 93, 218, 0.2);
}
</style>

import store from "@/store/index";
import axios from "axios";
import router from "@/router";
//стор фоток в корзине
export default {
  state: {},

  getters: {},

  mutations: {},

  actions: {
    async addToCart({state}, {string}) {
      try {
        const response = await axios({
          baseURL: store.getters.URL + '/api/user/user_cart',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + store.getters.token
          },
          method: 'POST',
          data: JSON.stringify({
            photo: string
          })
        })
        // await store.dispatch('loginUser', {token: store.getters.token})
      } catch (e) {
        console.log(e)
      } finally {

      }
    },
    async deleteFromCart({state}, {string}) {
      await store.dispatch('setIsLoading', true);
      try {
        const response = await axios({
          baseURL: store.getters.URL + '/api/user/user_cart/' + string,
          headers: {
            'Accept': '*/*',
            'Authorization': 'Bearer ' + store.getters.token
          },
          method: 'DELETE',
        })
        // await store.dispatch('loginUser', {token: store.getters.token})
      } catch (e) {
        console.log(e)
      } finally {
        await store.dispatch('setIsLoading', false);
      }
    }
  },
};

import store from "@/store/index";
import axios from "axios";
import router from "@/router";
//стор купленных фото
export default {
  state: {
    photoLocations: localStorage.getItem('photoLocations') ? JSON.parse(localStorage.getItem('photoLocations')) : [],
  },

  getters: {
    photoLocations: state => state.photoLocations
  },

  mutations: {
    GET_PHOTO_LOCATIONS(state, array) {
      state.photoLocations = array
    },
  },

  actions: {
    getPhotoLocations({commit}, payload) {
      commit('GET_PHOTO_LOCATIONS', payload.array)
    },
    async fetchLocations() {
      await store.dispatch('setIsLoading', true)
      try {
        const response = await axios({
          baseURL: store.getters.URL + '/api/user/user_photos_filters',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${store.getters.token}`
          },
          method: 'GET',
        })
        const mappedArray = response.data.map(location => {
          return {
            location: {
              address: location.location.address,
              title: location.location.title,
              id: location.location.id,
              dates: location.dates
            }
          }
        })
        await store.dispatch('getPhotoLocations', {array: mappedArray})
        localStorage.setItem('photoLocations', JSON.stringify(mappedArray))
      } catch (e) {
        console.log(e)
        if (e.response.data.message === 'Expired JWT Token' || e.response.data.message === 'Invalid credentials.') {
          await store.dispatch('logoutUser')
          await router.push({name: 'home'});
          await store.dispatch('setIsAlert', true)
          await store.dispatch('changeAlertMessage', 'Зайдите для продолжения работы с сервисом')
          await store.dispatch('changeAlertType', 'info')
          setTimeout(() => {
            store.dispatch('setIsAlert', false)
          }, 3000)
        }
      } finally {
        await store.dispatch('setIsLoading', false)
      }
    }
  },
};


//ключ от юкасы
export default {
  state: {
    idempotenceKey: null,
  },

  getters: {
    idempotenceKey: (state) => state.idempotenceKey,
  },

  mutations: {
    CHANGE_IDEMPOTENCE_KEY(state, key) {
      state.idempotenceKey = key;
    }
  },

  actions: {
    changeIdempotenceKey({state, commit}, key) {
      commit('CHANGE_IDEMPOTENCE_KEY', key)
    }
  },
};
